import { cn } from "movestic-core/utils/tailwind-utils";

export const H1Hero = ({ children, className, ...props }: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h1 {...props} className={cn("text-heroPhone md:text-hero font-neutraface", className)}>
        {children}
    </h1>
);

export const H1 = ({ children, className, ...props }: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h1 className={cn("text-h1 mb-2 font-neutraface", className)} {...props}>
        {children}
    </h1>
);

export const H2 = ({ children, className, ...props }: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h2 className={cn("text-h2 mb-2 font-neutraface", className)} {...props}>
        {children}
    </h2>
);

export const H3 = ({ children, className, ...props }: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h3 className={cn("text-h3 mb-2 font-neutraface", className)} {...props}>
        {children}
    </h3>
);

export const H4 = ({ children, className, ...props }: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h4 className={cn("text-h4 mb-2 font-neutraface", className)} {...props}>
        {children}
    </h4>
);

export const H5 = ({ children, className, ...props }: React.HTMLAttributes<HTMLHeadingElement>) => (
    <h5 className={cn("text-h5 mb-2 font-neutraface", className)} {...props}>
        {children}
    </h5>
);

export const TextLarge = ({ children, className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => (
    <span className={`text-large ${className}`} {...props}>
        {children}
    </span>
);

export const TextSmall = ({ children, className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => (
    <span className={`text-small ${className}`} {...props}>
        {children}
    </span>
);

export const TextStrong = ({ children, className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => (
    <span className={`font-bold ${className}`} {...props}>
        {children}
    </span>
);

export const Q = ({ children, className, ...props }: React.HTMLAttributes<HTMLQuoteElement>) => (
    <q
        className={`text-q font-proxima-nova font-italic text-cherryRed ${className}`}
        style={{ fontStyle: "italic", quotes: "none" }}
        {...props}
    >
        {children}
    </q>
);
