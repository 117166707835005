import Link from "next/link";
import { UrlObject } from "url";
import { cn } from "../utils/tailwind-utils";

export type LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {
    href: string | UrlObject;
    disabled?: boolean;
};

const BaseButton = ({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button
        className={cn(
            `flex items-center justify-center no-underline text-center box-border cursor-pointer gap-2 min-h-10 leading-6 px-4 py-0 rounded-lg disabled:opacity-20 disabled:pointer-events-none`,
            className,
        )}
        {...props}
    >
        {children}
    </button>
);

export const BaseLinkButton = ({ children, className, href, target, disabled, ...props }: LinkProps) => (
    <Link
        href={href}
        target={target}
        className={cn(
            `flex items-center justify-center no-underline text-center box-border cursor-pointer min-w-[85px] min-h-10 leading-6 px-4 py-0 rounded-lg ${disabled ? "opacity-50 pointer-events-none" : ""}`,
            className,
        )}
        {...props}
    >
        {children}
    </Link>
);

export const PrimaryButton = ({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <BaseButton
        className={cn(`font-bold text-white bg-cherryRed font-proximanova hover:bg-cherryDark `, className)}
        {...props}
    >
        {children}
    </BaseButton>
);

export const PrimaryLinkButton = ({ children, className, ...props }: LinkProps) => (
    <BaseLinkButton
        className={cn(`font-bold text-white bg-cherryRed font-proximanova hover:bg-cherryDark`, className)}
        {...props}
    >
        {children}
    </BaseLinkButton>
);

export const SecondaryButton = ({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <BaseButton
        className={cn(
            `font-bold border-cherryDark border border-solid text-cherryDark font-proximanova hover:bg-cherryDark hover:text-white `,
            className,
        )}
        {...props}
    >
        {children}
    </BaseButton>
);

export const SecondaryLinkButton = ({ children, className, ...props }: LinkProps) => (
    <BaseLinkButton
        className={cn(
            `font-bold border-cherryDark border text-cherryDark font-proximanova hover:bg-cherryDark hover:border-cherryDark hover:text-white disabled:(border-cherryDark text-cherryDark opacity-50) `,
            className,
        )}
        {...props}
    >
        {children}
    </BaseLinkButton>
);

export const PrimaryLink = ({ children, className, href, ...props }: LinkProps) => (
    <Link
        className={cn(`font-bold text-cherryDark no-underline hover:text-cherryRed`, className)}
        href={href}
        {...props}
    >
        {children}
    </Link>
);

export const TextLinkButton = ({ children, className, ...props }: React.BaseHTMLAttributes<HTMLDivElement>) => (
    <div className={`underline cursor-pointer flex gap-2 ${className}`} {...props}>
        {children}
    </div>
);

export const TextButton = ({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <BaseButton
        className={cn(`font-bold font-proximanova text-cherryDark hover:text-cherryRed `, className)}
        {...props}
    >
        {children}
    </BaseButton>
);
